export const SharedActionTypes = {
    SET_AUTH_RULES: 'SET_AUTH_RULES',
    SET_ACCESSIBLE_SCREENS: 'SET_ACCESSIBLE_SCREENS',
    SHOW_INNER_SPINNER: 'SHOW_INNER_SPINNER',
    LOGO_CHANGED: 'LOGO_CHANGED',
    USER_TODOS: 'USER_TODOS',
    TOGGLE_TODOS: 'TOGGLE_TODOS',
    HIDE_TODOS: 'HIDE_TODOS',
    NAVIGATE_TODOS_TAB: 'NAVIGATE_TODOS_TAB',
    PASSWORD_NOT_STRONG: 'PASSWORD_NOT_STRONG',
    LEFT_MENU_OPEN: 'LEFT_MENU_OPEN',
};