import axios, { CancelTokenSource } from "axios";
import { APIConstants } from "../shared/constants";
import { UrlUtils } from "../utils/http-url.util";
import { getAction } from "./action";
import { ConfigActionTypes } from "./config.action-types";


export const getConfigurations = (dispatch: any) => {
    axios.get(UrlUtils.getBaseUrl() + APIConstants.configuration)
        .then((result: any) => result?.data)
        .then((result: any) => {
            dispatch(getAction(ConfigActionTypes.SET_CONFIGURATIONS, result));
        })
        .catch((err: any) => {
            dispatch(getAction(ConfigActionTypes.REMOVE_CONFIGURATIONS, null));
        });
}