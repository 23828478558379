import produce from "immer";

import { AuthActionTypes } from "../actions/auth.action-types";
import { ConfigActionTypes } from "../actions/config.action-types";
import { IPayLoad } from "../models/interfaces/shared";
import { ConfigInitialState } from "./config.initialState";


export const ConfigReducer = (state = ConfigInitialState, action: IPayLoad): any => {
    return produce(state, (draft) => {
        switch (action.type) {
            case ConfigActionTypes.SET_CONFIGURATIONS: {
                draft.configurations = action.payload?.configurations;
                break;
            }
            case ConfigActionTypes.REMOVE_CONFIGURATIONS:
            case AuthActionTypes.SET_LOGOUT:
                return ConfigInitialState;
            default:
                break;
        }
    });
}