export const CreateLetterActionTypes = {
    SET_LETTER_SOURCE: 'SET_LETTER_SOURCE',
    SET_LETTER_OPTIONS: 'SET_LETTER_OPTIONS',
    SET_SELECTED_ACCOUNTS: 'SET_SELECTED_ACCOUNTS',
    SET_LETTER_ACCOUNTS: 'SET_LETTER_ACCOUNTS',
    SET_LETTER_TEMPLATE_WITH_AUTOSELECT: 'SET_LETTER_TEMPLATE_WITH_AUTOSELECT',
    SET_LETTER_TEMPLATE: 'SET_LETTER_TEMPLATE',
    SET_LETTER_ADVANCED: 'SET_LETTER_ADVANCED',
    SET_LETTER_ACTIVETAB: 'SET_LETTER_ACTIVETAB',
    SET_TEMP_LETTER_RELOAD: 'SET_TEMP_LETTER_RELOAD',
    SET_CUSTOMER_ROUND_INFO: 'SET_CUSTOMER_ROUND_INFO',
    SET_TEMP_LETTERS: 'SET_TEMP_LETTERS',
    SET_DISPUTE_LETTERS_RELOAD: 'SET_DISPUTE_LETTERS_RELOAD',
    SET_LETTERS_CREATED: 'SET_LETTERS_CREATED',
    SET_AUTOSELECT_OPTIONS: 'SET_AUTOSELECT_OPTIONS',
    RESET_ACCOUNTS: 'RESET_ACCOUNTS',
    SET_LAST_REPORT_DATE: 'SET_LAST_REPORT_DATE'
};