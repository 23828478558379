import produce from "immer";

import { IPayLoad } from "../models/interfaces/shared";
import { AuthActionTypes } from "../actions/auth.action-types";
import { ImportReportInitialState } from "./import-report.initial-state";
import { ImporterActionTypes } from "../actions/importer.action-types";

export const ImportReportReducer = (state = ImportReportInitialState, action: IPayLoad): any => {
    return produce(state, (draft) => {
        switch (action.type) {
            case ImporterActionTypes.SET_DELETED_ACCOUNTS_COUNTS:
                draft.deletedAccounts = action.payload;
                break;
            case ImporterActionTypes.SET_DELETED_INQUIRIES_COUNTS:
                draft.deletedCreditInquiries = action.payload;
                break;
            case ImporterActionTypes.SET_NEW_INQUIRIES_COUNTS:
                draft.newCreditInquiries = action.payload;
                break;
            case ImporterActionTypes.SET_NEW_NEGATIVE_ACCOUNTS_COUNTS:
                draft.newNegativeAccounts = action.payload;
                break;
            case ImporterActionTypes.SET_NEW_POSITIVE_ACCOUNTS_COUNTS:
                draft.newPositiveAccounts = action.payload;
                break;
            case ImporterActionTypes.SET_UPDATED_ACCOUNTS_COUNTS:
                draft.updatedAccounts = action.payload;
                break;
            default:
                break;
        }
    });
}