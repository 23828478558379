export const CustomerActionTypes = {
    SET_STATUSES: 'SET_STATUSES',
    SET_API_ACTIVE: 'SET_API_ACTIVE',
    SET_STATES: 'SET_STATES',
    SET_PARTNER_KEY: 'SET_PARTNER_KEY',
    SET_S3_FILES: 'SET_S3_FILES',
    SET_ADMIN_SETTINGS: 'SET_ADMIN_SETTINGS',
    SET_AGENTS: 'SET_AGENTS',
    SET_DISPUTE_STATS: 'SET_DISPUTE_STATS',
    SET_DISPUTE_STATS_LOADING: 'SET_DISPUTE_STATS_LOADING',
    SET_FETCH_REPORT_DATE: 'SET_FETCH_REPORT_DATE',
    SET_IS_LOADING_FETCH_REPORT: 'SET_IS_LOADING_FETCH_REPORT',
};